import { useContext } from "react";
import { DataContext } from "../../context/DataContext";

import closeIcon from "../../assets/cross.svg";
import deleteIcon from "../../assets/delete.svg";
import downloadIcon from "../../assets/download.svg";

import "./style.css";
import axios from "axios";
import { ERROR_MESSAGE } from "../../Constants/errorMessages";

export default function Operations() {
	const {
		selectedDirectories,
		selectedFiles,
		setSelectedFiles,
		setSelectedDirectories,
		setMessage,
	} = useContext(DataContext);

	async function deleteQueue() {
		for (let index = 0; index < selectedDirectories.length; index++) {
			const directoryId = selectedDirectories[index];

			try {
				const { data } = await axios.delete(`/directory/${directoryId}`);

				if (data.success) {
					setMessage({
						error: false,
						message: data.message,
					});
				} else {
					setMessage({
						error: true,
						message: data.message,
					});
				}
			} catch (error) {
				setMessage({
					error: true,
					message: ERROR_MESSAGE,
				});
			}
		}

		for (let index = 0; index < selectedFiles.length; index++) {
			const fileId = selectedFiles[index];

			try {
				const { data } = await axios.delete(`/file/${fileId}`);

				if (data.success) {
					setMessage({
						error: false,
						message: data.message,
					});
				} else {
					setMessage({
						error: true,
						message: data.message,
					});
				}
			} catch (error) {
				setMessage({
					error: true,
					message: ERROR_MESSAGE,
				});
			}
		}

		setSelectedDirectories([]);
		setSelectedFiles([]);

		setTimeout(() => {
			window.location.reload();
		}, 1000);
	}

	async function download() {
		for (let index = 0; index < selectedDirectories.length; index++) {
			const directoryId = selectedDirectories[index];

			setMessage({
				error: false,
				message: "Zipping directory...",
			});

			try {
				const data = await axios.get(`/directory/download/${directoryId}`, {
					headers: {
						"Content-Type": "application/zip",
					},
					responseType: "blob",
				});

				const blob = new Blob([data.data], {
					type: "application/zip",
				});

				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");

				a.download = "archive.zip";
				a.href = url;
				a.click();

				window.URL.revokeObjectURL(url);

				if (data.data) {
					setMessage({
						error: false,
						message: data.message,
					});
				} else {
					setMessage({
						error: true,
						message: data.message,
					});
				}
			} catch (error) {
				setMessage({
					error: true,
					message: ERROR_MESSAGE,
				});
			}
		}
	}

	return (
		<ul id="operations">
			<li>
				<img
					src={closeIcon}
					alt={"Clear selection"}
					onClick={() => {
						setSelectedDirectories([]);
						setSelectedFiles([]);
					}}
				/>
			</li>

			<li>
				<img
					src={deleteIcon}
					onClick={() => deleteQueue()}
					alt={"Delete selected items"}
				/>
			</li>

			<li>
				<img
					src={downloadIcon}
					onClick={() => download()}
					alt={"Download selected items"}
				/>
			</li>
		</ul>
	);
}
