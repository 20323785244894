import "./style.css";
import { Link } from "react-router-dom";
import petalStudioIcon from "../../assets/petalstudio.svg";
import showIcon from "../../assets/show.svg";
import menuIcon from "../../assets/menu.svg";
import { useRef, useState } from "react";
import ADMIN_ROLES from "../../Constants/adminRoles";

function NavActions({ setShowActions }) {
	return (
		<ul className="nav-actions" onMouseLeave={() => setShowActions(false)}>
			<li>
				<Link to={"/forgot-password"}>Reset Password</Link>
			</li>
			<li>
				<Link to={"/login"}>Login</Link>
			</li>
			<li>
				<Link to="/logout">Logout</Link>
			</li>
		</ul>
	);
}

export default function Nav() {
	const [showActions, setShowActions] = useState(false);
	const navLinks = useRef();

	const user = localStorage.getItem("user");
	const role = localStorage.getItem("role");

	return (
		<nav id="nav">
			<img
				className="menu-icon"
				src={menuIcon}
				alt="menu"
				onClick={() => {
					navLinks.current.classList.toggle("toggle-menu");
				}}
			/>
			<ul id="nav-logo">
				<li>
					<Link
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							fontFamily: "Contrail One, sans-serif",
							fontWeight: 400,
							fontSize: "13px",
							fontStyle: "normal",
						}}
						to="/home"
					>
						<img
							style={{ width: "40px", marginRight: "15px" }}
							src={petalStudioIcon}
							alt="Petal Studio"
						/>
						Petals Studio
					</Link>
				</li>
			</ul>

			<ul className="nav-links" ref={navLinks}>
				<li>
					<Link to="/home">Home</Link>
				</li>
				<li>
					<Link to="/home/shared">Shared</Link>
				</li>
				<li className={`${!ADMIN_ROLES.includes(role) ? "disabled" : ""}`}>
					<Link to="/users">View Users</Link>
				</li>
				<li id="profile">
					{user ? <div id="avatar"> {user[0].toUpperCase()} </div> : null}
					{user ? (
						<div>{user.length > 11 ? user.slice(0, 11) + "..." : user}</div>
					) : (
						<Link to="/login">Login</Link>
					)}
					<img
						src={showIcon}
						className="nav-img"
						alt="Show profile"
						onClick={() => {
							setShowActions(!showActions);
						}}
					/>
				</li>

				{showActions && (
					<NavActions setShowActions={setShowActions} user={user} />
				)}
			</ul>
		</nav>
	);
}
