import React, { useContext, useEffect } from "react";
import axios from "axios";

import "./style.css";
import { useNavigate } from "react-router-dom";
import { ERROR_MESSAGE } from "../../Constants/errorMessages";
import { DataContext } from "../../context/DataContext";

export default function Logout() {
	const navigate = useNavigate();
	const { setMessage } = useContext(DataContext);

	useEffect(() => {
		async function logout() {
			try {
				const { data } = await axios.post("/logout");

				if (data.success) {
					localStorage.clear();
					navigate("/login");

					setMessage({
						error: false,
						message: data.message,
					});
				} else {
					setMessage({
						error: true,
						message: data.message,
					});
				}
			} catch (error) {
				setMessage({
					error: true,
					message: ERROR_MESSAGE,
				});
			}
		}

		logout();
	}, []);

	return <div></div>;
}
