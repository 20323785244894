import crossIcon from "../../assets/cross.svg";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../context/DataContext";
import axios from "axios";
import { ERROR_MESSAGE } from "../../Constants/errorMessages";
import "./styles.css";

export default function Details() {
	const { setViewDetails, directoryId, selectedFile, setMessage } =
		useContext(DataContext);
	const [details, setDetails] = useState({});
	const [events, setEvents] = useState([]);
	const [detailsActive, setDetailsActive] = useState(true);
	const style = {
		color: "black",
		borderBottom: "solid 2px #000",
	}

	useEffect(() => {
		async function fetchDetails() {
			let url = `/directory/details/${directoryId}`;

			if (selectedFile) {
				url = `/file/details/${selectedFile._id}`;
			}

			try {
				const { data } = await axios.get(url);

				if (data.success) {
					if (data.data.file) {
						setDetails(data.data.file);
					} else {
						setDetails(data.data.directory);
					}
				} else {
					setMessage({
						error: true,
						message: data.message,
					});
				}
			} catch (error) {
				setMessage({
					error: true,
					message: ERROR_MESSAGE,
				});
			}
		}

		async function fetchEvents() {
			try {
				const { data } = await axios.get(`/directory/events/${directoryId}`);

				if (data.success) {
					setEvents(data.data.events);
				} else {
					setMessage({
						error: true,
						message: data.message,
					});
				}
			} catch (error) {
				setMessage({
					error: true,
					message: ERROR_MESSAGE,
				});
			}
		}

		fetchDetails();

		if (!detailsActive && directoryId) {
			fetchEvents();
		}
	}, [directoryId, selectedFile, detailsActive]);

	return (
		<div className="details">
			<img
				id="close-details"
				src={crossIcon}
				alt="cross"
				onClick={() => {
					setViewDetails(false);
				}}
			/>
			{details?.directoryName && (
				<p
					style={{
						fontSize: "20px",
						fontWeight: "700",
						marginLeft: "10px",
					}}
				>
					{details?.directoryName?.length > 10
						? details?.directoryName.slice(0, 10).toUpperCase() + "..."
						: details?.directoryName.toUpperCase()}
				</p>
			)}
			{details?.fileName && (
				<p
					style={{
						fontSize: "20px",
						fontWeight: "700",
						marginLeft: "10px",
					}}
				>
					{details?.fileName?.length > 10
						? details?.fileName.slice(0, 10).toUpperCase() + "..."
						: details?.fileName.toUpperCase()}
				</p>
			)}

			<div id="toggle-details-activity">
				<button
					style={detailsActive ? style : {}}
					onClick={() => setDetailsActive(true)}
				>
					Details
				</button>

				<button
					style={detailsActive ? {} : style}
					onClick={() => setDetailsActive(false)}
				>
					Activity
				</button>
			</div>

			{detailsActive && (
				<div id="details">
					<div className="detail-heading">Creator</div>
					<div className="detail-info">{details?.creatorEmail}</div>

					<div className="detail-heading">Created At</div>
					<div className="detail-info">
						{new Date(details?.createdAt).toLocaleDateString()}
					</div>

					{details.updatedAt && (
						<div className="detail-heading">Last Updated At</div>
					)}
					{details.updatedAt && (
						<div className="detail-info">
							{new Date(details?.updatedAt).toLocaleDateString()}
						</div>
					)}

					{details?.shared && <div className="detail-heading">Shared With</div>}

					{details?.shared?.length === 0 && (
						<div className="detail-info">Not Shared</div>
					)}

					{details.shared &&
						details.shared.map((user) => {
							return (
								<div
									style={{
										marginTop: "5px",
										fontWeight: "300",
										display: "flex",
										justifyContent: "space-between",
									}}
									key={user._id}
								>
									<div>{user.email}</div>
									<div>{user.access}</div>
								</div>
							);
						})}

					{details?.items && <div className="detail-heading">Items</div>}
					{details?.items && (
						<div className="detail-info">{details?.items}</div>
					)}

					<div className="detail-heading">Size</div>
					<div className="detail-info">{details?.size?.toFixed(2)} Kb</div>

					<div className="detail-heading">Description</div>
					<div className="detail-info">{details?.description}</div>
				</div>
			)}

			{!detailsActive && (
				<div id="activity">
					{events.map((event) => {
						return (
							<div key={event._id} className="activity-item">
								<div className="activity-heading">{event.event}</div>
								<div className="activity-info">
									{new Date(event.date).toLocaleDateString()}
								</div>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
}
