import shareIcon from "../../assets/share.svg";
import deleteIcon from "../../assets/delete.svg";
import renameIcon from "../../assets/rename.svg";
import pinIcon from "../../assets/pin.svg";
import moreIcon from "../../assets/more.svg";
import manageAccessIcon from "../../assets/access.svg";
import moveIcon from "../../assets/move.svg";
import infoIcon from "../../assets/info.svg";
import linkIcon from "../../assets/link.svg";
import Share from "./Share";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { DataContext } from "../../context/DataContext";
import "./style.css";
import { ERROR_MESSAGE } from "../../Constants/errorMessages";
import { useNavigate } from "react-router-dom";

async function deleteDirectory(directoryId, setMessage) {
	if (!directoryId) {
		setMessage({
			error: true,
			message: "Invalid directory!",
		});
		return;
	}

	try {
		const { data } = await axios.delete(`/directory/${directoryId.trim()}`);

		if (data.success) {
			setMessage({
				error: false,
				message: data.message,
			});

			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} else {
			setMessage({
				error: true,
				message: data.message,
			});
		}
	} catch (error) {
		setMessage({
			error: true,
			message: ERROR_MESSAGE,
		});
	}
}

async function pin(directoryId, setMessage) {
	if (!directoryId) {
		setMessage({
			error: true,
			message: "Invalid directory!",
		});
		return;
	}

	try {
		const { data } = await axios.post(`/directory/pin/${directoryId}`);
		if (data.success) {
			setMessage({
				error: false,
				message: data.message,
			});

			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} else {
			setMessage({
				error: true,
				message: data.message,
			});
		}
	} catch (error) {
		setMessage({
			error: true,
			message: ERROR_MESSAGE,
		});
	}
}

async function renameFolder(directoryId, directoryName, setMessage) {
	if (!directoryName || !directoryId) {
		setMessage({
			error: true,
			message: "Please enter a valid directory name",
		});
		return;
	}

	try {
		const { data } = await axios.patch(`/directory/rename/${directoryId}`, {
			updatedName: directoryName.toLowerCase().trim(),
		});

		if (data.success) {
			setMessage({
				error: false,
				message: data.message,
			});

			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} else {
			setMessage({
				error: true,
				message: data.message,
			});
		}
	} catch (error) {
		setMessage({
			error: true,
			message: ERROR_MESSAGE,
		});
	}
}

export default function ActionsDirectory({
	setShowDirectoryActions,
	X,
	Y,
	setShowManageAccess,
	setShowMove,
}) {
	const {
		setMessage,
		directoryId,
		setDirectoryId,
		setSelectedFile,
		setViewDetails,
	} = useContext(DataContext);
	const [directoryName, setDirectoryName] = useState("");
	const [showShare, setShowShare] = useState(false);
	const actionsDirectoryRef = useRef();
	const shareList = useRef();
	const navigate = useNavigate();

	useEffect(() => {
		if (actionsDirectoryRef.current) {
			actionsDirectoryRef.current.style.left = X + window.scrollX + "px";
			actionsDirectoryRef.current.style.top = Y + window.scrollY + "px";
		}
	}, [X, Y]);

	return (
		<Fragment>
			{showShare && (
				<Share
					setShowShare={setShowShare}
					shareList={shareList}
					directoryId={directoryId}
					actionsDirectoryRef={actionsDirectoryRef}
				/>
			)}
			<ul
				ref={actionsDirectoryRef}
				className="actions"
				onMouseLeave={() => {
					if (!shareList.current) {
						setShowDirectoryActions(false);
					}
				}}
			>
				<li
					onClick={() => {
						setShowShare(true);
					}}
				>
					<img src={shareIcon} alt="share" />
					Share
					<img style={{ marginLeft: "auto" }} src={moreIcon} alt="more" />
				</li>

				<hr className="actions-hr" />
				<li
					onClick={() => {
						pin(directoryId, setMessage);
						setShowShare(false);
						setShowDirectoryActions(false);
					}}
				>
					<img src={pinIcon} alt="pin" />
					Pin
				</li>
				<li>
					<img src={renameIcon} alt="rename" autoFocus />
					<input
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								renameFolder(
									directoryId,
									directoryName.trim().toLowerCase(),
									setMessage
								);
							}
						}}
						onChange={(e) =>
							setDirectoryName(e.target.value.trim().toLowerCase())
						}
						placeholder="Rename"
						id="rename-folder"
					/>
				</li>
				<hr className="actions-hr" />
				<li
					onClick={() => {
						deleteDirectory(directoryId, setMessage);
						setShowShare(false);
						setShowDirectoryActions(false);
					}}
				>
					<img src={deleteIcon} alt="delete" />
					Delete
				</li>
				<hr className="actions-hr" />
				<li
					onClick={() => {
						setShowManageAccess(true);
						setShowShare(false);
						setShowDirectoryActions(false);
					}}
				>
					<img src={manageAccessIcon} alt="access" />
					Manage Access
				</li>
				<li
					onClick={() => {
						setShowMove(true);
						setShowShare(false);
						setShowDirectoryActions(false);
					}}
				>
					<img src={moveIcon} alt="access" />
					Move
				</li>
				<li
					onClick={() => {
						setDirectoryId(directoryId);

						setSelectedFile(null);
						setViewDetails(true);

						setShowShare(false);
						setShowDirectoryActions(false);
					}}
				>
					<img src={infoIcon} alt="info" />
					Details
				</li>
				<hr className="actions-hr" />
				<li
					onClick={() => {
						setSelectedFile(null);

						setShowShare(false);
						setShowDirectoryActions(false);

						navigate(`/share/${directoryId}`);
					}}
				>
					<img src={linkIcon} alt="info" />
					Get Sharable Link
				</li>
			</ul>
		</Fragment>
	);
}
