import React, {
	Fragment,
	useContext,
	useEffect,
	useState,
} from "react";
import axios from "axios";
import { ERROR_MESSAGE } from "../../Constants/errorMessages";
import Nav from "../../components/Nav/Nav";
import "./style.css";
import { DataContext } from "../../context/DataContext";
import { useParams } from "react-router-dom";
import copyIcon from "../../assets/copy.svg";
import shareIcon from "../../assets/share.svg";
import Message from "../../components/Message/Message";

export default function SharePage() {
	const [url, setUrl] = useState(null);
	const { id: directoryId } = useParams();
	const { message, setMessage } = useContext(DataContext);
	const [access, setAccess] = useState("view");

	useEffect(() => {
		async function generateUrl() {
			try {
				const { data } = await axios.get(`/directory/share/${directoryId}`, {
					params: {
						access,
					},
				});

				if (data.success) {
					setUrl(data.data.url);

					setMessage({
						error: false,
						message: `Link generated successfully [${access}]`,
					});
				} else {
					setMessage({
						error: true,
						message: data.message,
					});
				}
			} catch (error) {
				setMessage({
					error: true,
					message: ERROR_MESSAGE,
				});
			}
		}

		generateUrl();
	}, [access, directoryId]);

	return (
		<Fragment>
			<Nav />

			{message && <Message message={message} setMessage={setMessage} />}

			<div id="shared-link">
				<p id="share-link-heading">
					Share this link with your friends
					<img src={shareIcon} alt="share" />
				</p>

				<div id="share-link-url">
					{!url && <p id="url">Generating Link...</p>}
					{url && <p id="url">{url + "..."}</p>}
					<button
						id="copy-link"
						onClick={() => {
							navigator.clipboard.writeText(url);
							setMessage({
								type: false,
								message: "Link copied to clipboard",
							});
						}}
					>
						<img src={copyIcon} alt="copy" />
					</button>
				</div>

				<div id="access">
					<p>
						<strong>Access:</strong>
					</p>
					<label>View</label>
					<input
						type="checkbox"
						name="access"
						id="view"
						value="view"
						checked={access === "view"}
						onChange={(e) => setAccess(e.target.value)}
					/>

					<label>Edit</label>
					<input
						type="checkbox"
						name="access"
						id="edit"
						value="edit"
						checked={access === "edit"}
						onChange={(e) => setAccess(e.target.value)}
					/>
				</div>

				<p className="warning warning-heading">
					<strong>Note:</strong>
				</p>
				<ul className="warning">
					<li>Anyone with this link can access your directory.</li>
					<li>Do not share this link with anyone you do not trust.</li>
					<li>This link is valid only for 10 hrs.</li>
					<li>User must be authenticated to access the contents.</li>
					<li>
						After using this link, others will be able to access the content
						until you remove them.
					</li>
				</ul>
			</div>
		</Fragment>
	);
}
