import "./style.css";
import React, { Fragment, useContext } from "react";
import { useState } from "react";
import axios from "axios";
import petalStudioIcon from "../../assets/petalstudio.svg";
import { Link, useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import Message from "../../components/Message/Message";
import { ERROR_MESSAGE } from "../../Constants/errorMessages";

export default function Login() {
	const { message, setMessage } = useContext(DataContext);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const navigate = useNavigate();

	async function handleLogin(e) {
		e.preventDefault();

		try {
			const { data } = await axios.post("/login", {
				email,
				password,
			});

			if (data.success) {
				localStorage.setItem("token", data?.data.token);
				localStorage.setItem("user", data?.data.user);
				localStorage.setItem("role", data?.data.role);

				setMessage({
					error: false,
					message: data.message,
				});

				setTimeout(() => {
					navigate("/home");
				}, 1000);
			} else {
				setMessage({
					error: true,
					message: data.message,
				});
			}
		} catch (error) {
			setMessage({
				error: true,
				message: ERROR_MESSAGE,
			});
		}
	}

	return (
		<Fragment>
			{message && <Message message={message} setMessage={setMessage} />}
			<form id="login-form" onSubmit={(e) => handleLogin(e)}>
				<Link to={"/"} id="login-logo">
					<img src={petalStudioIcon} alt="logo" />
					<span>Petal Studio</span>
				</Link>

				<h1>Sign in</h1>

				<input
					autoFocus
					type="email"
					placeholder="Email"
					onChange={(e) => setEmail(e.target.value)}
					required
				/>
				<input
					type="password"
					placeholder="Password"
					onChange={(e) => setPassword(e.target.value)}
					required
				/>
				<div id="forgot-password">
					<Link to={"/forgot-password"}>Forgot Password?</Link>
				</div>
				<button>Login</button>
			</form>
		</Fragment>
	);
}
